import React from "react";
import {
  Box,
  Typography,
  styled,
  Stack
} from "@mui/material";


const JobTabPanelBox = styled('div')(({ theme }) => ({
  height: "100%",
  width: '100%',
}));

const PanelContentStack = styled(Stack)(({ theme }) => ({
  height: "100%",
  padding: theme.spacing(2),
  width: '100%',
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: {};
}

export function JobTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <JobTabPanelBox
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}

      {...other}
    >
      <PanelContentStack>{children}</PanelContentStack>
    </JobTabPanelBox>
  );
}