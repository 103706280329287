import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import ModalPaper from "components/modals/ModalPaper";
import { StyledButton } from "../StyledActivityButton";
import { ModalProps } from "types/generic";

export const CallSummaryModal: React.FC<ModalProps> = ({
  row,
  triggerText,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const title: string = row?.user?.profile
    ? `${row.user.profile.fullName} - Call summary`
    : `Anonymous - Call summary`;
  const jobCalls = row?.jobCalls;

  return (
    <>
      <ModalPaper
        title={title}
        trigger={<StyledButton>{triggerText}</StyledButton>}
        isOpen={isOpen}
        setOpen={setOpen}
      >
        <Box>
          {jobCalls && jobCalls.callSummary ? (
            <Typography>{jobCalls?.callSummary}</Typography>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
              <Typography variant="body1" color="text.secondary">
                No call summary yet.
              </Typography>
            </Box>
          )}
        </Box>
      </ModalPaper>
    </>
  );
};
