import React from "react";

import { useApolloClient} from "@apollo/client";
import { CREATE_JOB_LINK_ACTIVITY } from "api/mutations";
import { JobType } from 'types/generic.d'

export const useActivities = (job: JobType) => {
  const apolloClient = useApolloClient();

  const createActivity = (text: string, activityType: number) => {
    try {
      const response = apolloClient.mutate({
        mutation: CREATE_JOB_LINK_ACTIVITY,
        variables: {
          jobId: job.id,
          action: text,
          activityType: activityType,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return ({
    createActivity
  });
};
