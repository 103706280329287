import { Box, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { Colors } from "constants/colors";
import { ActivityModal } from "./DashboardActivityModals/ActivityModal";
import { CallSummaryModal } from "./DashboardActivityModals/CallSummaryModal";
import { CallModal } from "./DashboardActivityModals/CallModal";
import { ChatsSummaryModal } from "./DashboardActivityModals/ChatSummaryModal";
import { ChatsModal } from "./DashboardActivityModals/ChatsModal";
import { UserModal } from "./DashboardActivityModals/UserModal";
interface DataRow {
  user: {
    profile: {
      id: string;
      fullName: string;
      birthDate: string;
      jobTitle: string;
      location: string;
      phone: string;
      telegram: string;
      avatar: string;
      workFrom: number;
      contactEmail: string | null;
    };
    id: string;
    email: string;
    isActive: boolean;
    isStaff: boolean;
    dateJoined: string;
  };
}
const JobPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  backgroundColor: Colors.paperBackground,
  width: "100%",
  minWidth: "fit-content",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],

  "&:not(:last-child)": {
    marginBottom: theme.spacing(2),
  },
}));
const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
}));
const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.secondary,
  minWidth: "50%",
  fontSize: 14,
}));
interface JobActivitiesTableProps {
  data: DataRow[];
}
export const JobActivitiesCard: React.FC<JobActivitiesTableProps> = ({
  data,
}) => {
  return (
    <Box
      style={{
        width: "100%",
        marginTop: "15px",
      }}
    >
      {" "}
      {data.length > 0 ? (
        data?.map((item) => (
          <JobPaper>
            <StyledBox>
              <TitleTypography>User </TitleTypography>
              <UserModal
                row={item}
                triggerText={
                  item?.user ? item.user.profile.fullName : 'Anonymous'
                }
              />
            </StyledBox>{" "}
            <StyledBox>
              <TitleTypography>Activity </TitleTypography>
              <ActivityModal row={item} triggerText={"Click to view"} />
            </StyledBox>{" "}
            <StyledBox>
              <TitleTypography>Chats </TitleTypography>
              <ChatsModal row={item} triggerText={"Click to view"} />
            </StyledBox>{" "}
            <StyledBox>
              <TitleTypography>Chat summary </TitleTypography>
              <ChatsSummaryModal row={item} triggerText={"Click to view"} />
            </StyledBox>{" "}
            <StyledBox>
              <TitleTypography>Calls </TitleTypography>
              <CallModal row={item} triggerText={"Click to view"} />
            </StyledBox>{" "}
            <StyledBox>
              <TitleTypography>Call summary </TitleTypography>
              <CallSummaryModal row={item} triggerText={"Click to view"} />
            </StyledBox>
          </JobPaper>
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: 2,
          }}
        >
          <Typography variant="body1" color="text.secondary">
            No data yet.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
