import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_JOB_BY_ID } from "../../api/queries";
import {
  Box,
  Button,
  Stack,
} from "@mui/material";
import { PATHS } from "../../router/routes";
import KnowledgeBase from "components/KnowledgeBase";

export const DashboardJobKnowledgeBase = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const { data, loading } = useQuery(GET_JOB_BY_ID, {
    variables: {
      id: jobId,
    },
  });

  return (
    <Box m={2}>
      <Stack direction={"column"} gap={2} mb={3}>
        <KnowledgeBase job={data?.getJob}/>
        <Button
          variant="contained"
          onClick={() => navigate(PATHS.DASHBOARD_JOBS)}
          color={"secondary"}
          fullWidth
          sx={{ maxWidth: 500, alignSelf: 'center' }}
        >
          Back
        </Button>
      </Stack>{" "}
    </Box>
  );
};
