import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  styled,
  TextareaAutosize,
  useMediaQuery,
} from "@mui/material";
import ModalPaper from "components/modals/ModalPaper";
import useKnowledgeBaseMutations from "../hooks/useKnowledgeBaseMutations";
import { JobType } from "types/generic";

type KnowledgeBaseTextModalProps = {
  job: JobType
};

const ModalBody = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const ModalFooter = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

const Textarea = styled(TextareaAutosize)(({ theme }) => ({
  resize: "none",
  padding: theme.spacing(3),
  fontFamily: theme.typography.fontFamily,
}));

const TriggerButton = styled(Button)(({ theme }) => ({
  width: "100%",
  fontSize: 14,
  [theme.breakpoints.down('md')]: {
      fontSize: 12
  }
}));


const KnowledgeBaseTextModal: React.FC<KnowledgeBaseTextModalProps> = ({
  job,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [text, setText] = useState("");
  const { uploadText } = useKnowledgeBaseMutations(job);

  const createText = (source: string) => {
    if (!source) return;

    uploadText({ 
      variables: {
        jobId: job.id,
        text: source
      }
    })
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ModalPaper
      title={`Add knowledge base text`}
      trigger={
        <TriggerButton variant="contained">
          Add Text
        </TriggerButton>
      }
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <ModalBody>
        <Stack>
          <Textarea
            minRows={22}
            maxRows={22}
            autoFocus={true}
            onChange={(e) => setText(e.target.value)}
            value={text}
          />
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
        >
          <Button
            variant="contained"
            onClick={() => {
              createText(text);
              setText("");
              onClose();
            }}
          >
            Update
          </Button>
        </Stack>
      </ModalFooter>
    </ModalPaper>
  );
};

export default KnowledgeBaseTextModal;
