export const VOICE_OPTIONS = [
    { key: 1, text: 'Matilda', value: '1' },
    { key: 2, text: 'Tony', value: '2' },
    { key: 3, text: 'Nancy', value: '3' }
];

export const VOICE_MAPPER = {
    1: 'Matilda',
    2: 'Tony',
    3: 'Nancy'
} as { [key: number]: string };

export const SUBSCRIPTION_BACKEND_URL = process.env.REACT_APP_SUBSCRIPTION_BACKEND_URL;