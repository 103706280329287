import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  styled,
  Box,
  Typography,
  Avatar,
  Divider,
} from "@mui/material";
import { PATHS } from "router/routes.js";
import { authContext } from "providers/auth";
import MenuItem from "@mui/material/MenuItem";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { LOGOUT_USER } from "../../api/mutations";
import { useApolloClient } from "@apollo/client";
import BaseHeader from "./BaseHeader";
import BasicPopover from "components/mui/BasicPopover";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    height: 24,
    width: 24,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        height: 20,
        width: 20,
        marginRight: theme.spacing(1),
    }
}));

const UserBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: theme.spacing(2),
    transition: "background-color 0.3s ease, border 0.3s ease",
    [theme.breakpoints.down('sm')]: {
        padding: "2px 6px",
    }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '& svg': {
        marginRight: theme.spacing(1), 
        fontSize: 22, 
        color: theme.palette.violet.main
    }
}));

function PublicHeader() {
  const { isAuth, setAuth, user, refetchUser } = useContext(authContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const apolloClient = useApolloClient();
  const [isProfile, toggleProfile] = useState(PATHS.PROFILE === pathname);

  useEffect(()=>{
    !user && refetchUser();
  },[])

  const logout = () => {
    setAuth(null);
    sessionStorage.clear();
    localStorage.clear();
    apolloClient.cache.reset();
    apolloClient.mutate({
        mutation: LOGOUT_USER,
    });
    navigate(PATHS.SIGN_IN);
  };

  const navigateToProfile = () => {
    navigate(PATHS.PROFILE);
    toggleProfile(true);
  }

  const navigateToJob = () => {
    navigate(`/${localStorage.getItem('job')}`)
    toggleProfile(false);
  }

  if (!isAuth) return (
    <BaseHeader>
      <Button
        variant="outlined"
        color='secondary'
        onClick={() => {
        navigate(PATHS.SIGN_IN);
        }}
      >
          Sign in
      </Button>
    </BaseHeader>
  )

  if (isAuth && user) return (
    <BaseHeader>
        <div>
            <BasicPopover 
                trigger={
                    <UserBox>
                        <StyledAvatar src={user?.profile?.avatar}/>
                        <Typography variant="body2" color="primary.contrastText">
                            {user.profile.fullName}
                        </Typography>
                        <ArrowDropDownIcon sx={{ color: "primary.contrastText", ml: 0.5 }} />
                    </UserBox>
                }
            >
                <StyledMenuItem
                    onClick={() => isProfile
                        ? navigateToJob()
                        : navigateToProfile()
                    }
                >
                    <AccountCircleOutlinedIcon/> {isProfile ? 'Job' : 'Profile'}
                </StyledMenuItem>
                <Divider sx={{ backgroundColor: "secondary", margin: '0 !important' }} />
                <StyledMenuItem onClick={logout}>
                    <ExitToAppOutlinedIcon/> {" "}Log Out
                </StyledMenuItem>
            </BasicPopover>
        </div>
    </BaseHeader>
  );

  return (
    <BaseHeader><></></BaseHeader>
  );
}

export default PublicHeader;
