import React, { useEffect } from "react";
import {
  Box,
  Button,
  styled,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATHS } from "router/routes";

const AccessDeniedContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.main,
}));

const AccessDeniedTitle = styled(Typography)(({ theme }) => ({
  fontSize: "3rem",
  color: theme.palette.grey[600],
  marginBottom: theme.spacing(2),
}));

const AccessDeniedText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(4),
  color: theme.palette.grey[400],
}));

const Errors = {
  AUTH_ERROR: "Authentication required to view this page."
}

export const NoAuthPlaceholder = ({ errorMessage }: { errorMessage: string }) => {
    const navigate = useNavigate();

    useEffect(()=>{
      !Errors.AUTH_ERROR.includes(errorMessage) && navigate(PATHS.PAGE_NOT_FOUND);
    },[])

    if (Errors.AUTH_ERROR == errorMessage) return (
        <AccessDeniedContainer>
          <AccessDeniedTitle variant="h2">Access Denied</AccessDeniedTitle>
          <AccessDeniedText variant="h6">You need to be authorized to view this page.</AccessDeniedText>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(PATHS.SIGN_IN)}
          >
            Login
          </Button>
        </AccessDeniedContainer>
    )

    return <></>;
};
