import React, { useContext } from 'react';
import BaseHeader from './BaseHeader';
import { authContext } from "providers/auth";
import { Button, styled } from '@mui/material';
import { LOGOUT_USER } from '../../api/mutations';
import { PATHS } from '../../router/routes';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const LogoutButton = styled(Button)(({ theme }) => ({
    color: "#fff",
    marginLeft: "auto",
    border: "1px solid rgba(255, 255, 255, 0.6)",
    padding: "6px 16px",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  }));

const DashboardHeader = () => {
    const { isAuth, setAuth } = useContext(authContext);

    const navigate = useNavigate();
    const apolloClient = useApolloClient();

    const logout = () => {
        setAuth(null);
        sessionStorage.clear();
        localStorage.clear();
        apolloClient.cache.reset();
        apolloClient.mutate({
            mutation: LOGOUT_USER,
        });
        navigate(PATHS.SIGN_IN);
    };

    return (
        <BaseHeader>
            {isAuth && (
                <LogoutButton onClick={logout}>Log Out</LogoutButton>
            )}
        </BaseHeader>
    )
}

export default DashboardHeader;