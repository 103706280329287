import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import ModalPaper from "components/modals/ModalPaper";
import { StyledButton } from "../StyledActivityButton";
import { ModalProps } from "types/generic";

export const ChatsSummaryModal: React.FC<ModalProps> = ({
  row,
  triggerText,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const title: string = row?.user?.profile
    ? `${row?.user.profile.fullName} - Chat summary`
    : `Anonymous -  Chat summary`;

  const jobChats = row?.jobChats;
  return (
    <>
      <ModalPaper
        title={title}
        trigger={<StyledButton>{triggerText}</StyledButton>}
        isOpen={isOpen}
        setOpen={setOpen}
      >
        <Box>
          {jobChats && jobChats.chatSummary ? (
            <Typography>{jobChats?.chatSummary}</Typography>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
              <Typography variant="body1" color="text.secondary">
                No chat summary yet.
              </Typography>
            </Box>
          )}
        </Box>
      </ModalPaper>
    </>
  );
};
