import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_JOB_BY_ID } from "api/queries";
import useUpdateJob from "components/hooks/useUpdateJob";
import {
  Box,
  Button,
  Stack,
  styled,
  Tab,
  Tabs,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Chat from "components/Dashboard/Chat";
import React, { useEffect, useState } from "react";
import Loader from "components/Loader";
import PaperContainer from "components/containers/PaperContainer";
import { PATHS } from "../../router/routes";
import { purple } from "@mui/material/colors";

const TrainingGuideStack = styled(Stack)(({ theme }) => ({
  // gap: theme.spacing(3),
}));
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: {};
}
const TrainingGuideTextarea = styled(TextareaAutosize)(({ theme }) => ({
  resize: "none",
  // minHeight: '350px',
  padding: theme.spacing(3),
  fontFamily: theme.typography.fontFamily,
  borderColor: theme.palette.divider,
}));
function TabPanel(props: TabPanelProps) {
  const { children, value, index, style, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={style}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            pt: 2,
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export const DashboardJobTrainingGuide = () => {
  const { jobId } = useParams();
  const { data, loading } = useQuery(GET_JOB_BY_ID, {
    variables: {
      id: jobId,
    },
  });
  const [trainingGuideText, setTrainingGuide] = useState(" ");
  const { onSubmit } = useUpdateJob(data?.getJob);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(1);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    if (data && !loading) {
      setTrainingGuide(data?.getJob.trainingGuide);
    }
  }, [data, loading]);
  if (loading) return <Loader />;
  return (
    <PaperContainer
      title={"Edit training guide"}
      closeIcon={true}
      callback={() => navigate(PATHS.DASHBOARD_JOBS)}
    >
      <Tabs sx={{ width: "100%" }} value={tabValue} onChange={handleChange}>
        <Tab
          value={1}
          sx={{ width: "50%" }}
          label={
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              Current Training Guide
            </span>
          }
        />
        <Tab
          value={2}
          sx={{ width: "50%" }}
          label={
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              Chat with Matilda
            </span>
          }
        />
      </Tabs>

      <TabPanel value={tabValue} index={1} style={{ width: "100%" }}>
        {" "}
        <TrainingGuideStack>
          <Typography variant="h5">Current Training Guide</Typography>
          <Stack spacing={1}>
            <Typography>
              Simulate conversation with your virtual HR
            </Typography>
          </Stack>
          <TrainingGuideTextarea
            maxRows={23}
            minRows={23}
            autoFocus={true}
            onChange={(e) => setTrainingGuide(e.target.value)}
            value={trainingGuideText}
          />
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "40px",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={() => navigate(PATHS.DASHBOARD_JOBS)}
              color={"secondary"}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onSubmit({ trainingGuide: trainingGuideText }, () =>
                  navigate(PATHS.DASHBOARD_JOBS),
                );
              }}
            >
              Update
            </Button>
          </Stack>
        </TrainingGuideStack>
      </TabPanel>

      <TabPanel value={tabValue} index={2} style={{ width: "100%" }}>
        <Chat job={data?.getJob} />
      </TabPanel>
    </PaperContainer>
  );
};
