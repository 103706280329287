import { Avatar, Box, Chip, Stack, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import ModalPaper from "components/modals/ModalPaper";
import moment from "moment";
import { StyledButton } from "../StyledActivityButton";
import { ModalProps } from "types/generic";

const AvatarBox = styled(Box)(({theme}) => ({
  position: "relative",
  width: 120,
  height: 120,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  borderRadius: "50%",
}));

const BoldTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500
}))

const TitleTypography = styled(BoldTypography)(({ theme }) => ({
  textAlign: "center",
}));

const UnauthorizedPlaceholderText = styled('p')(({ theme }) => ({
  cursor: "not-allowed",
  marginLeft: theme.spacing(2),
  fontWeight: 400,
}))

export const UserModal: React.FC<ModalProps> = ({ row, triggerText }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const title: string = row?.user?.profile
    ? `${row?.user.profile.fullName} - Profile`
    : " ";
  const user = row?.user;
  
  const calculateAge = (birthDate: string) => {
    if (!birthDate) return null;
    const birth = moment(birthDate);
    const age = moment().diff(birth, "years");

    return `${age}`;
  };

  return (
    <>
      {user ? (
        <ModalPaper
          title={title}
          trigger={<StyledButton>{triggerText}</StyledButton>}
          isOpen={isOpen}
          setOpen={setOpen}
          width={320}
        >
          <Stack direction="column" alignItems="center" spacing={2}>
            <AvatarBox>
              <Avatar
                sx={{ width: 100, height: 100 }}
                src={user?.profile?.avatar}
              />
            </AvatarBox>
            {user?.profile?.fullName && (
              <span>
                <TitleTypography variant="h5">{user.profile.fullName}</TitleTypography>
                {user.profile.birthDate && 
                  <TitleTypography
                    ml={1}
                    variant="h5"
                  >
                    {calculateAge(user.profile.birthDate)}
                  </TitleTypography>
                }
              </span>
            )}

            {user?.profile?.jobTitle && (
              <Typography
                variant="body1"
                color="text.secondary"
                textAlign="center"
              >
                {user.profile.jobTitle}
              </Typography>
            )}
            <Stack spacing={1}>
              {user?.email && (
                <Box>
                  <BoldTypography>Email:</BoldTypography>
                  <Typography><a href={`mailto:${user.email}`}>{user.email}</a></Typography>
                </Box>
              )}

              {user?.profile?.phone && (
                <Box>
                  <BoldTypography>Phone:</BoldTypography>
                  <Typography>{user.profile.phone}</Typography>
                </Box>
              )}

              {user?.profile?.telegram && (
                <Box>
                  <BoldTypography>Telegram:</BoldTypography>
                  <Typography>{user.profile.telegram}</Typography>
                </Box>
              )}

              {user?.profile?.location && (
                <Box>
                  <BoldTypography>Location:</BoldTypography>
                  <Typography>{user.profile.location}</Typography>
                </Box>
              )}

            </Stack>
            {user?.profile?.workFrom != null && (
              <Chip 
                sx={{padding: 2}}
                label={                  
                  <Typography variant="body1">
                    Prefers work from {` `}
                    <strong>
                      {user.profile.workFrom === 1 ? "office" : "home"}
                    </strong>
                  </Typography>
                } 
              />
            )}
          </Stack>
        </ModalPaper>
      ) : (
        <UnauthorizedPlaceholderText>
          {triggerText}
        </UnauthorizedPlaceholderText>
      )}
    </>
  );
};
