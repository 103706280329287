import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { SOCIAL_AUTH } from "api/mutations";
import { PATHS } from "router/routes";
import { authContext } from "providers/auth";


const useLinkedInSignin = (
  loading: boolean,
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void,
) => {
  const navigate = useNavigate();
  const { setAuth } = useContext(authContext);
  const [code, setCode] = useState<null | string>(null);
  
  const [mutateSocialAuth, { data: authData, error: authError }] = useMutation(
    SOCIAL_AUTH,
    {
      onCompleted(data) {
        console.log("authDataDebug", data);

        onSignIn(data?.socialAuth?.message);
      },
      onError: (error) => {
        console.error("Apollo mutation error:", error.message);
      },
    },
  );

  useEffect(() => {
    getSearchParam(setCode, "code");
  }, []);

  useEffect(() => {
    code && makeAuthMutation(code);
    code && setLoading(true);
  }, [code]);

  const getSearchParam = (callback: (arg0: string) => void, param: string) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeParam = urlParams.get(param);
    codeParam && callback(codeParam);
  };

  const makeAuthMutation = useCallback(
    (code: string) => {
      mutateSocialAuth({
        variables: {
          accessToken: code,
        },
      });
    },
    [code],
  );

  const onSignIn = (token = null) => {
    if (!token) return;
    token && localStorage.setItem("token", token);
    setLoading(false);
    setAuth(true);
    const job = localStorage.getItem("job");
    if (job) {
      localStorage.removeItem("job");
      setTimeout(() => navigate(`/${job}`), 0);
    } else {
      setTimeout(() => navigate(PATHS.DASHBOARD_JOBS), 0);
    }
  };
};

export default useLinkedInSignin;
