export const ProfileType = `
  profile {
    id
    fullName
    birthDate
    jobTitle
    location
    phone
    telegram
    avatar
    workFrom
    contactEmail
  }
`;

export const UserType = `
  user {
    id
    email
    isActive
    isStaff
    dateJoined
    ${ProfileType}
  }
`;

export const JobType = `
  job {
    id
    jobName
    jobTitle
    voiceType
    greeting
    trainingGuide
    activeUntil
    jobCode
    authRequired 
    textAlert
    createdAt
  }
`;

export const JobLinkActivityType = `
  jobLinkActivities {
    id
    text
    createdAt
    activityType
  }
`;
export const jobChatMessagesType = `
  jobChatMessages { 
    createdAt
    id
    message
    typeFrom
  }
`;
export const JobChatType = `
  jobChats {
    id
    chatSummary
    createdAt
    ${jobChatMessagesType}
  }
`;
export const JobCallMessageType = `
    jobCallMessages {
      createdAt
      id
      typeFrom
      message
    }`;

export const JobCallType = `
  jobCalls {
    id
    callSummary
    createdAt
    ${JobCallMessageType}
  }
`;

export const JobActivitiesType = `
  jobActivities {
    id
    ${UserType}
    ${JobType}
    createdAt
    ${JobLinkActivityType}
    ${JobChatType}
    ${JobCallType}
  }
`;

export const KnowledgeUploadType = `
  knowledgeData {
    id
    source
    sourceType
    processingStatus
    createdAt
  }
`
