import React, { FC, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Paper,
  styled,
  Typography,
  IconButton,
  Snackbar,
} from "@mui/material";
import { JobType } from "types/generic";
import SettingsPopover from "./SettingsPopover";
import SelectVoice from "./SelectVoice";
import GreetingModal from "components/modals/GreetingsModal";
import SettingsModal from "components/modals/SettingsModal";
import TrainingGuideModal from "components/modals/TrainingGuideModal";
import JobNameModal from "components/modals/JobNameModal";
import KnowledgeBaseModal from "components/modals/KnowledgeBaseModal";
import ActivateJobButton from "./ActivateJobButton";
import { isActiveDate } from "utils/common";
import CopyJobLinkButton from "./CopyJobLinkButton";

type JobProps = {
  job: JobType;
  key: React.Key;
};

const JobPaper = styled(Paper)(({ theme }) => ({
  height: 250,
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "row",
  [theme.breakpoints.down("lg")]: {
    height: 220,
  },
}));

const GridContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  // gridTemplateRows: "5fr 1fr 2fr",
  gridTemplateRows: "31px 31px 42px 42px 40px",
  gridTemplateColumns: "repeat(6, 1fr)",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    gridTemplateRows: "18px 18px 40px 40px",
    "& p": {
      fontSize: "14px",
    },
  },
}));

const RawStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
}));

const LabelTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const EditBox = styled(Box)(({ theme }) => ({
  alignSelf: "end",
}));

const EditModalButton = styled(Button)(({ theme }) => ({
  width: "fit-content"
}));

const BTag = styled('b')(() => ({
  fontWeight: 500
}))

const Job: FC<JobProps> = ({ job }) => {
  const { id, jobName, activeUntil } = job;

  return (
    <JobPaper>
      <GridContainer>
        <Box>
          <RawStack gap={1}>
            <Typography>{jobName}</Typography>
            <IconButton sx={{ height: 40 }}>
              <JobNameModal job={job} />
            </IconButton>
          </RawStack>
        </Box>
        <Box>
          {isActiveDate(activeUntil) && (
            <Typography><BTag>Visitors</BTag></Typography>
          )}
        </Box>
        <Box>
          {isActiveDate(activeUntil) && (
            <Typography><BTag>Chats: {job.chats}</BTag></Typography>
          )}
        </Box>
        <Box>
          {isActiveDate(activeUntil) && (
            <Typography><BTag>Calls: {job.calls}</BTag></Typography>
          )}
        </Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box>
          {isActiveDate(activeUntil) && (
            <Typography>Today: {job.visitorsToday}</Typography>
          )}
        </Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box>
          {isActiveDate(activeUntil) && (
            <Typography>Since activation: {job.visitorsSinceActivation}</Typography>
          )}
        </Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box>
          <LabelTypography>VOICE</LabelTypography>
        </Box>
        <Box>
          <LabelTypography>GREETING</LabelTypography>
        </Box>
        <Box>
          <LabelTypography>TRAINING GUIDE</LabelTypography>
        </Box>
        <Box>
          <LabelTypography>SETTINGS</LabelTypography>
        </Box>
        <Box>
          <LabelTypography>KNOWLEDGE BASE</LabelTypography>
        </Box>
        <Box>
          <RawStack style={{ marginTop: "-8px" }}>
            <ActivateJobButton job={job} />
          </RawStack>
        </Box>
        <EditBox sx={{ minWidth: 220 }}>
          <SelectVoice job={job} />
        </EditBox>
        <EditBox>
          <GreetingModal
            job={job}
            trigger={
              <EditModalButton variant="contained">
                Edit
              </EditModalButton>
            }
          />
        </EditBox>
        <EditBox>
          <TrainingGuideModal
            job={job}
            trigger={
              <EditModalButton variant="contained">
                Edit
              </EditModalButton>
            }
          />
        </EditBox>
        <EditBox>
          <SettingsModal
            job={job}
            trigger={
              <EditModalButton variant="contained">
                Edit
              </EditModalButton>
            }
          />
        </EditBox>
        <EditBox>
          <RawStack gap={2}>
            <KnowledgeBaseModal
              job={job}
              trigger={
                <EditModalButton variant="contained">
                  Edit
                </EditModalButton>
              }
            />
          </RawStack>
        </EditBox>
        <EditBox style={{ display: "flex", justifyContent: "space-between" }}>
          <CopyJobLinkButton job={job} />
          <SettingsPopover id={id} />
        </EditBox>
      </GridContainer>{" "}
    </JobPaper>
  );
};

export default Job;
