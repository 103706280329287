import React from "react";
import { useMutation } from "@apollo/client";
import SettingsIcon from "@mui/icons-material/MoreVertOutlined";
import MenuItem from "@mui/material/MenuItem";

import { DELETE_JOB, DUPLICATE_JOB } from "api/mutations";
import BasicPopover from "components/mui/BasicPopover";
import { GET_JOBS } from "api/queries";

const SettingsPopover = ({ id }: { id: string }) => {
  const [mutateDeleteJob, { data: jobDeleted, error: deleteJobError }] =
    useMutation(DELETE_JOB, {
      refetchQueries: [{ query: GET_JOBS }],
      onCompleted(data) {
        console.log("deleteJobDebug", data);
      },
      onError: (error) => {
        console.error("Apollo mutation error:", error.message);
      },
    });
  const [mutateDuplicateJob, { data: jobDuplicate, error: duplicateJobError }] =
    useMutation(DUPLICATE_JOB, {
      refetchQueries: [{ query: GET_JOBS }],
      onCompleted(data) {
        console.log("duplicateJobDebug", data);
      },
      onError: (error) => {
        console.error("Apollo mutation error:", error.message);
      },
    });

  return (
    <BasicPopover trigger={<SettingsIcon />}>
      <MenuItem onClick={() => mutateDuplicateJob({ variables: { id } })}>
        Duplicate job
      </MenuItem>
      <MenuItem onClick={() => mutateDeleteJob({ variables: { id } })}>
        Delete job
      </MenuItem>
    </BasicPopover>
  );
};

export default SettingsPopover;
