import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  styled,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_JOB_ACTIVITIES, GET_JOBS } from "api/queries";
import { SelectAutocomplete } from "components/SelectAutocomplete";
import { DateFilter } from "./DateFilter";
import { JobActivitiesTable } from "./JobActivitiesTable";
import moment from "moment";
import { Colors } from "constants/colors";
import { JobActivitiesCard } from "./JobActivitiesCard";
import Loader from "../../components/Loader";
import DelayedLoaderText from "components/DelayedLoaderText";
interface OptionType {
  label: string;
  id: string | number;
}

const ActivityPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "row",
}));

const DashboardActivities = () => {
  const { data: dataJobs, loading: loadingJobs } = useQuery(GET_JOBS);
  const { data: dataJobActivities, loading: loadingJobActivities } =
    useQuery(GET_JOB_ACTIVITIES);
  const [filteredData, setFilteredData] = useState([]);
  const [authOnly, setAuthOnly] = useState(false);
  const [selectedJob, setSelectedJob] = useState<OptionType | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  useEffect(() => {
    if (dataJobActivities) {
      let newFilteredData = dataJobActivities?.getJobActivities;

      if (authOnly) {
        newFilteredData = newFilteredData?.filter(
          (item: { user: null }) => item.user != null,
        );
      }

      if (selectedJob) {
        newFilteredData = newFilteredData?.filter(
          (item: { job: { id: string | number } }) =>
            item?.job?.id === selectedJob?.id,
        );
      }
      if (startDate && endDate) {
        newFilteredData = newFilteredData?.filter(
          (item: { createdAt: Date }) =>
            startDate <= moment(item?.createdAt).toDate() &&
            moment(item?.createdAt).toDate() <= endDate,
        );
      }
      setFilteredData(newFilteredData);
    }
  }, [authOnly, selectedJob, dataJobActivities, startDate, endDate]);

  const handleChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setAuthOnly(event.target.checked);
  };

  if (loadingJobs || loadingJobActivities) return (
    <>
      <Loader />
      <DelayedLoaderText />
    </>
  )


  const dataJobSelect = dataJobs?.getJobs?.map(
    (item: { jobName: string; id: string | number }) => {
      return {
        label: item?.jobName,
        id: item?.id,
      };
    },
  );
  return (
    <ActivityPaper style={{ display: "flex", flexDirection: "column" }}>
      <Box
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {isMobile ? (
          <SelectAutocomplete
            isLoading={loadingJobs}
            options={dataJobSelect}
            placeholder={"Choose job"}
            value={selectedJob}
            onChange={(event, value) => setSelectedJob(value)}
            style={{ width: "100%" }}
          />
        ) : (
          <>
            <Typography variant={"h3"} style={{ marginRight: "15px" }}>
              Filtered by Job
            </Typography>
            <SelectAutocomplete
              isLoading={loadingJobs}
              options={dataJobSelect}
              placeholder={"Choose job"}
              value={selectedJob}
              onChange={(event, value) => setSelectedJob(value)}
              style={{ width: "200px" }}
            />
          </>
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <DateFilter setEndDate={setEndDate} setStartDate={setStartDate} />
        {isMobile ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            marginTop="10px"
          >
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              Authenticated users only
            </Typography>
            <Switch
              checked={authOnly}
              onChange={() => setAuthOnly((prevState) => !prevState)}
              color="primary"
            />
          </Box>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            fontSize={14}
            width={160}
            fontWeight={500}
          >
            Auth only <Switch checked={authOnly} onChange={handleChange} />
          </Box>
        )}
      </Box>{" "}
      {isMobile ? (
        <JobActivitiesCard data={filteredData} />
      ) : (
        <JobActivitiesTable data={filteredData} />
      )}
    </ActivityPaper>
  );
};

export default DashboardActivities;
