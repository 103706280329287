import React from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_JOB } from "api/mutations";
import { JobType, Language } from "types/generic";
import { GET_JOBS } from "api/queries";

const useUpdateJob = (job: JobType) => {
  const [updateJob] = useMutation(UPDATE_JOB, {
    onError(error) {
      console.log(error);
    },
  });

  async function onSubmit(values: Partial<JobType>, callBack?: () => any) {
    const variables = {
      jobData: {
        ...job,
        ...values,
      },
    };
    variables.jobData.languageIds = variables.jobData?.languageIds!?.length > 0 
      ? variables.jobData.languageIds?.map((item: number) => Number(item))
      : variables?.jobData?.languages?.map((item: Language) => Number(item?.id)) 
      || [];

    delete variables?.jobData?.visitors;
    delete variables?.jobData?.chats;
    delete variables?.jobData?.calls;
    delete variables?.jobData?.__typename;
    delete variables?.jobData?.createdAt;
    delete variables?.jobData?.languages;
    try {
      await updateJob({
        variables: variables,
        refetchQueries: [{ query: GET_JOBS }],
        async onCompleted(data) {
          if (data.updateJob.__typename === "SuccessType") {
            console.log("success");
            callBack && callBack();
          }
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  return {
    onSubmit,
  };
};

export default useUpdateJob;
