import React from "react";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import InvalidIcon from "@mui/icons-material/HighlightOffOutlined";
import {
  Stack,
  styled,
  Switch,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { UploadedData } from "./types";

const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between'
  }
}));

type ChangeStatusMutation = (mutation: { 
    variables: { 
        knowledgeDataId: UploadedData['id'], 
        status: boolean
    } 
}) => void;

interface StatusSwitchProps {
  knowledgeDataId: UploadedData['id']
  processingStatus: UploadedData['processingStatus']
  changeKnowledgeDataStatus: ChangeStatusMutation;
};

const StatusSwitch: React.FC<StatusSwitchProps> = ({
  knowledgeDataId,
  processingStatus,
  changeKnowledgeDataStatus
}) => {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const Status = {
    isActive: !!processingStatus,
    ACTIVE_STATUS: 2,
    DISABLED_STATUS: 0,
  };

  const nextStatus = (status: UploadedData['processingStatus']) => !!(status === Status.DISABLED_STATUS);

  const changeStatus = (status: UploadedData['processingStatus']) => {
    changeKnowledgeDataStatus({ 
      variables: { 
        knowledgeDataId, 
        status: nextStatus(status)
      } 
    })
  }

  return (
    <StyledStack>
        <Switch 
            checked={Status.isActive} 
            onChange={() => changeStatus(processingStatus)} 
            size={isTablet ? 'small' : 'medium'} 
        />
        {Status.isActive ? <CheckIcon /> : <InvalidIcon />}
    </StyledStack>
  );
};

export default StatusSwitch;
