import React, { FC, useState } from "react";
import {
  Box,
  Button,
  styled,
  IconButton,
  Snackbar,
} from "@mui/material";
import { JobType } from "types/generic";
import CloseIcon from "@mui/icons-material/Close";
import BaseSnackbar from "components/Snackbar";
import { useNotifications } from "@toolpad/core/useNotifications";

type CopyJobLinkButtonProps = {
  job: JobType;
};

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
    "& .MuiSnackbarContent-root": {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        fontWeight: "500",
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
}));


const CopyJobLinkButton: FC<CopyJobLinkButtonProps> = ({ job }) => {
  const notifications = useNotifications();

  return (
    <>
        <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (job?.jobCode) {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_URL}${job?.jobCode}`,
                );
              }
              notifications.show(job?.jobCode
                  ? "Job link copied to clipboard!"
                  : "Error! Fill in all fields in the Job settings form to receive link", 
                {
                  severity: job?.jobCode ? 'success': 'error',
                  autoHideDuration: 3000,
              });
      
            }}
        >
            GET JOB LINK
        </Button>
    </>
  )
};

export default CopyJobLinkButton;
