import React, { createContext } from "react";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { theme } from "../providers/theme";
import AppLayout from "./layout/AppLayout";
import { AuthProvider } from 'providers/auth';
import { NotificationsProvider } from "@toolpad/core/useNotifications";

const AppWrapper = ({ children }: {children: React.ReactNode }) => {

  return (
    <NotificationsProvider>
      <AuthProvider>
          <ThemeProvider theme={theme}>
              <CssBaseline />
              <AppLayout>{children}</AppLayout>
          </ThemeProvider>
      </AuthProvider>
    </NotificationsProvider>
  );
};

export default AppWrapper;
