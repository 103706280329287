import React from 'react';
import { useMutation, useQuery } from "@apollo/client";
import { GET_KNOWLEDGE_DATA } from "api/queries";
import { JobType } from 'types/generic';

const useKnowledgeData = (job: JobType) => {
    const { data: knowledgeData, loading } = useQuery(GET_KNOWLEDGE_DATA, {
        variables: {
            jobId: job?.id
        }
    });

    return {
        knowledgeData
    }
}

export default useKnowledgeData;