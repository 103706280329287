import React from "react";
import {
  Box,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { 
  CallButton, 
  useCallTimer,
} from 'components/Job';
import { JobType } from "types/generic";
import { formatWebCallTime } from './utils';
import { SUBSCRIPTION_BACKEND_URL } from "constants/common";
import { useConversation } from "vocode";

const CallBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  flex: 1,
  margin: "auto",
  gap: theme.spacing(2),
  minHeight: 400,
  border: `1px solid ${theme.palette.divider}`,
}));

export const WebCall = ({ job }: { job: JobType }) => {
    const { status, start, stop, error, analyserNode } = useConversation({
      backendUrl: `${SUBSCRIPTION_BACKEND_URL}/streaming/conversation/${job.jobCode}/`,
      audioDeviceConfig: {
        //   outputSamplingRate: 24000,  
      },
    });
    const isActiveCall = ["connected"].includes(status)

    const { callTime } = useCallTimer(isActiveCall);

    return (
      <Stack>
        <CallBox>
            <Typography>
                {isActiveCall ? formatWebCallTime(callTime) : "Speak to your HR assistant"}
            </Typography>
            <Typography>
              {isActiveCall ? "End call" : "Start call"}
            </Typography>
            <CallButton 
              isActiveCall={isActiveCall} 
              disabled={["connecting"].includes(status)}
              onClickAction={isActiveCall ? stop : start}
              job={job}
            />
        </CallBox>
      </Stack>

    );
};
