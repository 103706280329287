import React, { useState } from "react";
import {
  Button,
  Stack,
  styled,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import { JobType } from "types/generic";
import useUpdateJob from "components/hooks/useUpdateJob";

type Props = {
  job: JobType;
};

const TrainingGuideStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    }
  }));

const TrainingGuideTextarea = styled(TextareaAutosize)(({ theme }) => ({
  resize: "none",
  // minHeight: '350px',
  padding: theme.spacing(3),
  fontFamily: theme.typography.fontFamily,
  borderColor: theme.palette.divider,
}));

const TrainingGuide = ({ job }: Props) => {
  const { trainingGuide } = job;

  const [trainingGuideText, setTrainingGuide] = useState(trainingGuide);
  const { onSubmit } = useUpdateJob(job);

  return (
    <TrainingGuideStack>
        <TitleTypography variant="h5">Current Training Guide</TitleTypography>
        <Stack spacing={1}>
        <Typography color="text.secondary">
          Simulate conversation with your virtual HR
        </Typography>
        </Stack>
        <TrainingGuideTextarea
        maxRows={23}
        minRows={23}
        autoFocus={true}
        onChange={(e) => setTrainingGuide(e.target.value)}
        value={trainingGuideText}
        />
        <Button
        variant="contained"
        fullWidth
        onClick={() => onSubmit({ trainingGuide: trainingGuideText })}
        >
        Update Training Guide
        </Button>
    </TrainingGuideStack>
  );
};

export default TrainingGuide;
