import React, { useContext } from "react";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { authContext } from "providers/auth";
import DashboardSidebar from "components/Dashboard/Sidebar";
import {
  WorkOutline,
  EventNoteOutlined,
  Person2Outlined,
} from "@mui/icons-material";
import { PATHS } from "router/routes";

const SidebarDrawer = () => {
  const tabletLayout = useMediaQuery("(max-width:960px)");
  const { menuOpen, setMenuOpen } = useContext(authContext);

  const LINK_BUTTONS_MAPPER = [
    {
      label: "Profile",
      icon: <Person2Outlined />,
      href: PATHS.USER_PROFILE,
    },
  ];

  if (!tabletLayout) return (
    <DashboardSidebar linksMapper={LINK_BUTTONS_MAPPER}/>
  )

  return (
    <Drawer
      open={menuOpen}
      onClose={() => setMenuOpen(false)}
      // sx={{ '& > *': { height: '100%' }}}
    >
      <DashboardSidebar linksMapper={LINK_BUTTONS_MAPPER}/>
    </Drawer>
  );
};

export default SidebarDrawer;
