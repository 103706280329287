import React, { useEffect, useState } from "react";
import {
  Card,
  Stack,
  styled,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_JOB_BY_CODE} from "api/queries";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import Loader from "components/Loader";
import Chat from "components/Dashboard/Chat";
import {
  JobTabPanel,
  WebCall,
  useActivities,
  JobHeader,
  NoAuthPlaceholder
} from 'components/Job';

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const JobCard = styled(Card)(({ theme }) => ({
  width: 450,
  minWidth: 450,
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    minWidth: '100%'
  },
}));

const JobTab = styled(Tab)(({ theme }) => ({
  width: "50%",
  "& span": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.violet.main,
  },
}));


export const JobPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const {
    job,
    loading,
  } = useJobData();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (loading) return <Loader />;

  if (!loading && job.__typename === "ErrorType") {
    return <NoAuthPlaceholder errorMessage={job.message}/>
  }

  return (
    <Stack height={'100%'} p={2}>
      <JobCard
        elevation={10}
      >
        <Stack alignItems={"center"} spacing={2}>
          <JobHeader job={job}/>
          <Stack sx={{ width: "100%"}} alignItems={"center"}>
            <Tabs
              sx={{ width: "100%" }}
              value={tabValue}
              onChange={handleChange}
            >
              <JobTab
                value={0}
                {...tabProps(0)}
                label={
                  <span><ChatBubbleOutlineOutlinedIcon fontSize="small"/>Сhat</span>
                }
              />
              <JobTab
                value={1}
                {...tabProps(1)}
                label={
                  <span><PhoneOutlinedIcon fontSize="small"/>Call</span>
                }
              />
            </Tabs>
            <JobTabPanel
              value={tabValue}
              index={0}
            >
              <Chat job={job}/>
            </JobTabPanel>
            <JobTabPanel
              value={tabValue}
              index={1}
            >
              <WebCall job={job}/>
            </JobTabPanel>
          </Stack>
        </Stack>
      </JobCard>
    </Stack>
  );
};

const useJobData = () => {
  const { urlCode } = useParams();

  const { data, loading } = useQuery(GET_JOB_BY_CODE, {
    variables: {
      jobCode: urlCode,
    },
    fetchPolicy: "no-cache",
  });
  const job = data?.getJobByCode;

  const { createActivity } = useActivities(job)
  
  useEffect(() => {
    createJobActivity()
  }, [data, loading]);

  const createJobActivity = () => {
    if (loading || data?.getJobByCode.__typename !== "JobType") return;
    
    createActivity("Visited job", 1);
    typeof urlCode === "string" && localStorage.setItem("job", urlCode);
  }

  return ({
    job,
    loading
  })
}
