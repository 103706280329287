import React, { useState } from "react";
import {
  Box,
  Button,
  styled,
  Typography,
} from "@mui/material";
import ModalPaper from "components/modals/ModalPaper";
import { JobType } from "types/generic";

interface ApplyJobModalProps { 
    job: JobType, 
    callback: () => void 
}

const ApplyButton = styled(Button)(({ theme }) => ({
    '&:hover': { 
        // borderColor: theme.palette.violet.main, 
        // borderBottom: '3px solid', 
        // marginBottom: '-3px', 
        // borderRadius: 0 
    }
}));

const ApplyJobModal: React.FC<ApplyJobModalProps> = ({ job, callback }) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleApplyClick = () => {
    callback()
    if (job?.applyBtnPurpose === 1) {
      window.open(job?.applyBtnTo, "_blank");
    } else {
      window.location.href = `mailto:${job?.applyBtnTo}`;
    }
  };

  return (
    <ModalPaper
      title={`Apply to ${job?.jobTitle} job`}
      trigger={
        <ApplyButton
          variant="outlined"
          color="secondary"
          onClick={(e) => {
            e.stopPropagation(); 
            job?.textAlert ? setOpen(true) : handleApplyClick() 
          }}
        >
          <Typography variant='h5' color='secondary'>Apply</Typography>
        </ApplyButton>
      }
      isOpen={open} 
      setOpen={setOpen}
      width={320}
    >
      <Typography>{job?.textAlert}</Typography>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(false);
            handleApplyClick();
          }}
          sx={{ mt: 2 }}
        >
          Proceed
        </Button>
      </Box>
    </ModalPaper>
  )
}

export default ApplyJobModal;