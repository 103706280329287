import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Stack,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { JobType } from "types/generic";
import { KnowledgeData, UploadedData } from "./types";
import KnowledgeBaseTextModal from "./modals/KnowledgeBaseTextModal";
import KnowledgeBaseUrlModal from "./modals/KnowledgeBaseUrlModal";
import KnowledgeBaseMobileList from "./KnowledgeBaseMobileList";
import KnowledgeBaseDesktopList from "./KnowledgeBaseDesktopList";
import useKnowledgeData from './hooks/useKnowledgeData';
import useKnowledgeBaseMutations from "./hooks/useKnowledgeBaseMutations";

type GreetingsProps = {
  job: JobType;
};

const HeaderStack = styled(Stack)(({theme}) => ({
    flexDirection: "row",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column",
        maxWidth: 500,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
}))

const ActionsBox = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: "end",
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        alignItems: "center",
        gap: theme.spacing(1),
    },
    '& button': {
        fontSize: 14,
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        },
    }
}));



const KnowledgeBase = ({ job }: GreetingsProps) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const uploadRef = useRef<HTMLInputElement>(null);

  const { knowledgeData } = useKnowledgeData(job);
  const {
    uploadFile,
    uploadText,
    uploadUrl
  } = useKnowledgeBaseMutations(job);

  const createFile = (input: any) => {
    if (!input.target?.files) return;

    let file = input.target.files[0];

    if (!input.target.valid) {
      console.log('fileDebug', file)

      uploadFile({ 
        variables: {
          jobId: job.id,
          file
        }
      })
    }
  };

  return (
    <Box>
        <HeaderStack gap={2} mb={3}>
          <Typography variant="h3" flex="1" textAlign={"left"}>
            Agent Knowledge Base
          </Typography>
          <ActionsBox>
            <KnowledgeBaseTextModal job={job} />
            <KnowledgeBaseUrlModal job={job} />
            <Button
              variant="contained"
              fullWidth={isMobile}
              onClick={() => uploadRef?.current?.click()}
            >
              Upload File
            </Button>
            <input
              ref={uploadRef}
              type="file"
              // accept=".txt"
              style={{ display: "none" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                createFile(e);
                e.currentTarget.value = "";
              }}
            />
          </ActionsBox>
        </HeaderStack>
        {isMobile ? (
            <KnowledgeBaseMobileList 
              knowledgeData={knowledgeData?.getKnowledgeData?.knowledgeData ?? []} 
              job={job}
            />
          ) : (
            <KnowledgeBaseDesktopList 
              knowledgeData={knowledgeData?.getKnowledgeData?.knowledgeData  ?? []} 
              job={job}
            />
        )}
    </Box>
  );
};

export default KnowledgeBase;
