import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Stack,
  styled,
  Typography,
  IconButton,
  TextareaAutosize,
  useMediaQuery,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { JobType, ChatSubscription } from "types/generic";
import { SUBSCRIPTION_BACKEND_URL, VOICE_MAPPER } from "constants/common";
import useChatSubscription from 'components/hooks/useChatSubscription';

const ChatStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: 0,
  paddingBottom: 0,
  border: `1px solid ${theme.palette.divider}`,
  height: '100%',
  position:'relative'
}));

const ChatTextarea = styled(TextareaAutosize)(({ theme }) => ({
  resize: "none",
  padding: theme.spacing(3),
  paddingRight: theme.spacing(6),
  fontFamily: theme.typography.fontFamily,
  borderColor: theme.palette.divider,
  borderRadius: theme.spacing(1),
  '&:hover': {
    borderRadius: theme.spacing(1),
  },
  '&:focus-visible': {
    outlineStyle: 'solid',
    outlineWidth: '2px',
    outlineOffset: '-2px'
  }
}));

const MessagesStack = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(12),
  gap: theme.spacing(3),
  borderRadius: 3,
  flex: 1,
  overflowY: "auto",
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  [theme.breakpoints.down("md")]: {
    minHeight: '45vh',
    maxHeight: '45vh',
  }
}));

const AiMessageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  width: "85%",
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const UserMessageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  width: "85%",
  background: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  alignSelf: "flex-end",
}));

const SendIconStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  position: 'absolute',
  right: theme.spacing(5),
  height: '100%',
  WebkitBoxPack: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0
}));

const BackdropStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(3),
  backdropFilter: 'blur(3px)',
  background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 50%,rgba(255, 255, 255, 0.7) 50%, rgba(255,255,255,0) 100%)'
}))

interface Message {
  answer: string;
  type?: string;
}
interface ChatProps { 
  job: JobType, 
  height?: number, 
  withTitle?: boolean 
}

const Chat: React.FC<ChatProps> = ({ job, height = 400, withTitle = false }) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const [userMessage, setUserMessage] = useState<Message | null>(null);
  const { subscriptionData, sendMessage } = useChatSubscription(job.jobCode)

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [subscriptionData]);

  const onEnterMessage = (
    e: React.KeyboardEvent<HTMLTextAreaElement> | React.MouseEvent<HTMLElement>,
  ) => {
    if (!userMessage) return;

    const send = () => {
      sendMessage(userMessage);
      setUserMessage(null);
    };
    switch (e.type) {
      case "click":
        {
          send();
        }
        break;
      case "keydown": {
        const keyboardEvent = e as React.KeyboardEvent<HTMLTextAreaElement>;
        if (keyboardEvent.key === "Enter" && e.shiftKey == false) {
          keyboardEvent.preventDefault();
          send();
        }
      }
    }
  };

  return (
    <Stack gap={3}>
      {!isMobile && withTitle && <Typography variant="h5">{`Chat with ${VOICE_MAPPER?.[job.voiceType]}`}</Typography>}
        <ChatStack sx={!isMobile ? { minHeight: height, maxHeight: height } : {}}>
          <MessagesStack>
            {subscriptionData.map((message: Message, i: React.Key) => {
              switch (message.type) {
                case "user":
                  return (
                    <UserMessageBox key={i}>
                      <Typography>{message.answer}</Typography>
                    </UserMessageBox>
                  );
                default:
                  return (
                    <AiMessageBox key={i}>
                      <Typography>{message.answer}</Typography>
                    </AiMessageBox>
                  );
              }
            })}
            <div ref={messagesEndRef} />
          </MessagesStack>
          <BackdropStack>
            <ChatTextarea
              maxRows={2}
              // autoFocus={true}
              onChange={(e) => setUserMessage({ answer: e.target.value, type: "user" })}
              onKeyDown={onEnterMessage}
              value={userMessage?.answer ?? ''}
            />
            <SendIconStack>
              <IconButton onClick={onEnterMessage}>
                <SendIcon />
              </IconButton>
            </SendIconStack>
          </BackdropStack>
      </ChatStack>
    </Stack>
  );
};

export default Chat;
