import React from "react";
import {
  Stack,
  styled,
} from "@mui/material";
import DashboardProfile from "pages/dashboard-profile";
import PublicSidebarDrawer from "components/Profile/PublicSidebarDrawer";

const RootBox = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  padding: theme.spacing(3),
  height: "100%",
  gap: theme.spacing(3),
}));

const Profile = () => {
  return (
    <RootBox>
      <PublicSidebarDrawer />
      <DashboardProfile />
    </RootBox>
  );
};

export default Profile;
