import React, { useState } from "react";
import linkedInDefaultIcon from "assets/linked-in-default.png";
import linkedInHoverIcon from "assets/Linked-in-hover.png";
import { styled } from "@mui/material";


const LinkedInImage = styled("img")(({ theme }) => ({
  padding: theme.spacing(4),
  cursor: "pointer",
  width: "300px",
}));

const LinkedInButton = () => {
  const [isHovered, setIsHovered] = useState(false);

  const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID as string;
  const LINKEDIN_CALLBACK_URL = process.env
    .REACT_APP_LINKEDIN_CALLBACK_URL as string;
  const linkedinOAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    LINKEDIN_CALLBACK_URL,
  )}&scope=email%20profile%20w_member_social%20openid`;

  return (
    <LinkedInImage
      src={isHovered ? linkedInHoverIcon : linkedInDefaultIcon}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      alt="LinkedIn Sign In"
      onClick={() => (window.location.href = linkedinOAuthURL)}
    />
  );
};

export default LinkedInButton;
