import React, { useEffect, useState } from "react";
import { JobType, ChatSubscription } from "types/generic";
import { SUBSCRIPTION_BACKEND_URL } from "constants/common";


interface Message {
    answer: string;
    type?: string;
}

const useChatSubscription = (jobCode: JobType["jobCode"]) => {
    const chatSubscriptionUrl = `${SUBSCRIPTION_BACKEND_URL}/streaming/chat/${jobCode}/`;

    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [subscriptionData, setSubscriptionData] = useState<ChatSubscription[]>([]);

    useEffect(() => {
        if (socket) return;

        if (jobCode) {
            const newSocket = new WebSocket(chatSubscriptionUrl);

            newSocket.addEventListener('message', (event) => {
                setSubscriptionData((prev) => [...prev, JSON.parse(event.data)]);
            });
            setSocket(newSocket);
        }

        return () => {
            socket && (socket as WebSocket).close();
        };
    }, [jobCode, socket]);

    const sendMessage = (userMessage: Message) => {
        socket?.send(userMessage?.answer);
        setSubscriptionData((prev) => [...prev, userMessage]);
    };

    return {
        subscriptionData,
        sendMessage
    }

}

export default useChatSubscription