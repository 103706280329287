import React, { useState, useEffect } from 'react';

export const useCallTimer = (isActiveCall: boolean) => {
    const [callTime, setCallTime] = useState(0);
    
    useEffect(() => {
        let timer!: NodeJS.Timer
    
        if (isActiveCall) {
          timer = setInterval(() => {
            setCallTime((prevTime) => prevTime + 1);
          }, 1000);
        } else {
          setCallTime(0);
        }
    
        return () => {
          if (timer) {
            clearInterval(timer);
          }
        };
      }, [isActiveCall]);

    return {
        callTime
    }  
}