import React, { useState } from "react";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import InvalidIcon from "@mui/icons-material/HighlightOffOutlined";
import {
  Button,
  Stack,
  Paper,
  Typography,
  Switch,
  CardContent,
} from "@mui/material";
import { sortArrayByDate } from "utils/common";
import { Colors } from "constants/colors";
import { UploadedData } from './types.d';
import { JobType } from "types/generic";
import useKnowledgeBaseMutations from "./hooks/useKnowledgeBaseMutations";
import StatusSwitch from "./StatusSwitch";

interface KnowledgeBaseMobileListProps {
  knowledgeData: UploadedData[];
  job: JobType
};

const KnowledgeBaseMobileList = ({ knowledgeData, job }: KnowledgeBaseMobileListProps) => (
    <Stack alignItems='center'>
        {sortArrayByDate(knowledgeData).map((instanceData, i) => (
            <KnowledgeInstanceСard 
              key={i}
              instanceData={instanceData} 
              job={job}
            />
        ))}
    </Stack>
  );

type KnowledgeInstanceRowProps = {
  instanceData: UploadedData;
  job: JobType;
};

const KnowledgeInstanceСard: React.FC<KnowledgeInstanceRowProps> = ({
  instanceData,
  job
}) => {
  const { id, sourceType, source, createdAt, processingStatus } = instanceData;
  const {
    deleteKnowledgeData,
    retryUpload,
    changeKnowledgeDataText,
    changeKnowledgeDataStatus
  } = useKnowledgeBaseMutations(job);

  return (
    <Paper
      sx={{
        mb: 2,
        borderRadius: 1,
        background: Colors.paperBackground,
        maxWidth: 500,
        width: "100%",
        minWidth: "fit-content",
      }}
    >
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          SOURCE TYPE
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          sx={{ fontWeight: 600, mb: 1 }}
        >
          {sourceType}
        </Typography>

        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          SOURCE
        </Typography>
        <Typography variant="body1" color="textPrimary" sx={{ mb: 1.5 }}>
          {source}
        </Typography>

        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          DATE CREATED
        </Typography>
        <Typography variant="body2" color="textPrimary" sx={{ mb: 2 }}>
          {createdAt}
        </Typography>

        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          ACTIVE
        </Typography>
        <StatusSwitch
          knowledgeDataId={id}
          processingStatus={processingStatus}
          changeKnowledgeDataStatus={changeKnowledgeDataStatus}
        />

        <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 2 }}>
          <Button 
            variant="contained" 
            fullWidth 
            size="small" 
            onClick={() => deleteKnowledgeData({ variables: { knowledgeDataId: id } })}
          >
            Delete
          </Button>
        </Stack>
      </CardContent>
    </Paper>
  );
};

export default KnowledgeBaseMobileList;
