import React, { FC, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Paper,
  styled,
  Typography,
  IconButton,
  useMediaQuery,
  Snackbar,
  Link,
} from "@mui/material";
import { JobType } from "types/generic";
import SettingsPopover from "./SettingsPopover";
import SelectVoice from "./SelectVoice";
import JobNameModal from "components/modals/JobNameModal";
import ActivateJobButton from "./ActivateJobButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../router/routes";
import BaseSnackbar from "components/Snackbar";
import { useNotifications } from "@toolpad/core";

type JobProps = {
  job: JobType;
  key: React.Key;
};

const JobPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  maxWidth: 500,
  width: "100%",
  minWidth: "fit-content",
}));

const ContainerStack = styled(Stack)(({ theme }) => ({
  // width: "100%",
  gap: theme.spacing(2),
}));

const RawStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
}));

const EditBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));

const MobileJob: FC<JobProps> = ({ job }) => {
  const { id, jobName, activeUntil } = job;
  const notifications = useNotifications();
  const navigate = useNavigate();

  return (
    <JobPaper>
      <ContainerStack>
        <EditBox>
          <RawStack gap={1}>
            <Typography>{jobName}</Typography>
            <IconButton sx={{ height: 40 }}>
              <JobNameModal job={job} />
            </IconButton>
          </RawStack>
        </EditBox>
        <EditBox>
          <RawStack gap={1}>
            <SelectVoice job={job} />
            <SettingsPopover id={id} />
          </RawStack>
        </EditBox>
        <EditBox>
          <ActivateJobButton job={job} />
        </EditBox>
        <EditBox>
          <Button
            variant="contained"
            onClick={() =>
              navigate(PATHS.DASHBOARD_JOB_GREETING.replace(":jobId", job.id))
            }
            fullWidth
          >
            Greeting
          </Button>
        </EditBox>
        <EditBox>
          <Button
            variant="contained"
            onClick={() =>
              navigate(
                PATHS.DASHBOARD_JOB_TRAINING_GUIDE.replace(":jobId", job.id),
              )
            }
            fullWidth
          >
            Training guide
          </Button>
        </EditBox>
        <EditBox>
          <Button
            variant="contained"
            onClick={() =>
              navigate(PATHS.DASHBOARD_JOB_SETTINGS.replace(":jobId", job.id))
            }
            fullWidth
          >
            Settings
          </Button>
        </EditBox>
        <EditBox>
          <Button
            variant="contained"
            onClick={() =>
              navigate(
                PATHS.DASHBOARD_JOB_KNOWLEDGE_BASE.replace(":jobId", job.id),
              )
            }
            fullWidth
          >
            {" "}
            Knowledge base
          </Button>
        </EditBox>
        <EditBox>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => {
              if (job?.jobCode) {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_URL}${job?.jobCode}`,
                );
              }
                notifications.show(job?.jobCode
                  ? "Job link copied to clipboard!"
                  : "Error! Fill in all fields in the Job settings form to receive link", 
                  {
                    severity: job?.jobCode ? 'success': 'error',
                    autoHideDuration: 3000,
                });
            }}
          >
            GET JOB LINK
          </Button>{" "}
        </EditBox>
      </ContainerStack>
    </JobPaper>
  );
};

export default MobileJob;
