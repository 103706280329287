import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  styled,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import headerLogo from "assets/header-logo.svg";
import { PATHS } from "router/routes.js";
import { authContext } from "providers/auth";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

const HeaderLogo = styled("img")(({ theme }) => ({
  cursor: "pointer",
  [theme.breakpoints.only("sm")]: {
    flex: 1,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: theme.palette.primary.contrastText,
  [theme.breakpoints.not("sm")]: {
    display: "none",
  },
}));

const BaseHeader: React.FC<{ children?: ReactJSXElement }> = ({ children = null }) => {
  const { menuOpen, setMenuOpen } = useContext(authContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  return (
    <AppBar>
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <StyledIconButton
          aria-label="open drawer"
          onClick={() => setMenuOpen(!menuOpen)}
          edge="start"
          sx={[]}
        >
          <MenuIcon />
        </StyledIconButton>
        <HeaderLogo
          src={headerLogo}
          onClick={() => navigate(PATHS.ROOT)}
          style={{ height: isMobile ? 24 : 32 }}
          alt=""
        />
        {children}
      </Toolbar>
    </AppBar>
  );
}

export default BaseHeader;
