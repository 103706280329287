import PaperContainer from "components/containers/PaperContainer";

import React from "react";
import { useNavigate } from "react-router-dom";
import { CreateJobForm } from "components/forms/CreateJobForm";
import { PATHS } from "../../router/routes";

export const DashboardJobCreate = () => {
  const navigate = useNavigate();
  const onClose = () => {
    navigate(PATHS.DASHBOARD_JOBS);
  };
  return (
    <PaperContainer title="Create treaning guide for the AI HR manager">
      <CreateJobForm onClose={onClose} />
    </PaperContainer>
  );
};
