import React, { useState, useRef } from "react";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import InvalidIcon from "@mui/icons-material/HighlightOffOutlined";
import {
  Button,
  Stack,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
} from "@mui/material";
import { sortArrayByDate } from "utils/common";
import { UploadedData } from "./types";
import { JobType } from "types/generic";
import useKnowledgeBaseMutations from "./hooks/useKnowledgeBaseMutations";
import StatusSwitch from './StatusSwitch';

type KnowledgeBaseDesktopListProps = {
    knowledgeData: UploadedData[];
    job: JobType
};

const CenterTableCell = styled(TableCell)(({ }) => ({
  textAlign: "center",
}));

const KnowledgeBaseDesktopList = ({ knowledgeData, job }: KnowledgeBaseDesktopListProps) => (
    <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
        <TableHead>
            <TableRow>
            <CenterTableCell>SOURCE TYPE</CenterTableCell>
            <CenterTableCell>SOURCE</CenterTableCell>
            <CenterTableCell>DATE CREATED</CenterTableCell>
            <CenterTableCell>ACTIVE</CenterTableCell>
            <CenterTableCell></CenterTableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {sortArrayByDate(knowledgeData).map((instanceData, i) => (
              <KnowledgeInstanceRow 
                instanceData={instanceData} 
                job={job} 
                key={i} 
              />
            ))}
        </TableBody>
        </Table>
    </TableContainer>
);

type KnowledgeInstanceRowProps = {
  instanceData: UploadedData;
  job: JobType;
};

const TextTableCell = styled(CenterTableCell)(({ theme }) => ({
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: "300px",
}));

const KnowledgeInstanceRow: React.FC<KnowledgeInstanceRowProps> = ({
  instanceData,
  job
}) => {
  const { id, sourceType, source, createdAt, processingStatus } = instanceData;
  const {
    deleteKnowledgeData,
    retryUpload,
    changeKnowledgeDataText,
    changeKnowledgeDataStatus
  } = useKnowledgeBaseMutations(job);

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <CenterTableCell>{sourceType}</CenterTableCell>
      <TextTableCell>{source}</TextTableCell>
      <CenterTableCell>{new Date(createdAt)?.toLocaleDateString('en-US')}</CenterTableCell>
      <CenterTableCell>
        <StatusSwitch
          knowledgeDataId={id}
          processingStatus={processingStatus}
          changeKnowledgeDataStatus={changeKnowledgeDataStatus}
        />
      </CenterTableCell>
      <CenterTableCell>
        <Button 
          variant="contained" 
          color="secondary"
          onClick={() => deleteKnowledgeData({ variables: { knowledgeDataId: id } })}
        >
          Delete
        </Button>
      </CenterTableCell>
    </TableRow>
  );
};

export default KnowledgeBaseDesktopList;
