import { Box, Stack, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import ModalPaper from "components/modals/ModalPaper";
import { StyledButton } from "../StyledActivityButton";
import { ModalProps } from "types/generic";

const MessagesStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  gap: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 3,
  flex: 1,
  minHeight: 200,
  overflowY: "auto",
}));

const AiMessageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  width: "85%",
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const UserMessageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  width: "85%",
  background: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  alignSelf: "flex-end",
}));
export const CallModal: React.FC<ModalProps> = ({ row, triggerText }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const title: string = row?.user?.profile
    ? `${row?.user.profile.fullName} - Calls`
    : `Anonymous - Calls`;
  const jobChatsJobChatMessages = row?.jobCalls?.jobCallMessages;
  return (
    <>
      <ModalPaper
        title={title}
        trigger={<StyledButton>{triggerText}</StyledButton>}
        isOpen={isOpen}
        setOpen={setOpen}
        width={750}
      >
        <Box>
          {jobChatsJobChatMessages && jobChatsJobChatMessages?.length > 0 ? (
            <Box>
              <MessagesStack>
                {jobChatsJobChatMessages?.map((message, i) => {
                  switch (message?.typeFrom) {
                    case 1:
                      return (
                        <UserMessageBox key={i}>
                          <Typography>{message?.message}</Typography>
                        </UserMessageBox>
                      );
                    case 2:
                      return (
                        <AiMessageBox key={i}>
                          <Typography>{message?.message}</Typography>
                        </AiMessageBox>
                      );
                    default:
                      return null;
                  }
                })}
              </MessagesStack>
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
              <Typography variant="body1" color="text.secondary">
                No calls yet.
              </Typography>
            </Box>
          )}
        </Box>
      </ModalPaper>
    </>
  );
};
