import React, { useEffect } from "react";
import {
  Fab,
  Stack,
  styled,
  CircularProgress,
  IconButton,
  Box
} from "@mui/material";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ClearIcon from "@mui/icons-material/Clear";
import { useCallAction } from "./useCallAction";
import { useActivities } from "./useActivities";
import { JobType } from "types/generic";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(5),
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  mt: 2,
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
}));

const LoaderBox = styled(StyledBox)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.violet.main,
}));

const CallButtonStack = styled(Stack)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(5),
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  padding: 0,
  color: theme.palette.violet.main,
  fontSize: 28,
}));

interface CallButtonProps {
  isActiveCall: boolean; 
  disabled: boolean;
  onClickAction: () => void;
  job: JobType
}

export const CallButton: React.FC<CallButtonProps> = ({
  isActiveCall,
  disabled,
  onClickAction,
  job
}) => {
  const { createActivity } = useActivities(job);

  const callActionCallback = () => {
    !isActiveCall
        ? createActivity("End call", 5)
        : createActivity("Start call", 4)

    onClickAction()
  }

  const { 
      isPending, 
      setPending, 
      callActionProgress,
      cancelAction 
  } = useCallAction(callActionCallback);

  if (isPending) return (
    <LoaderBox>
      <StyledCircularProgress
          variant="determinate"
          size={50}
          value={callActionProgress}
      />
      <StyledIconButton onClick={cancelAction}>
          <ClearIcon />
      </StyledIconButton>
  </LoaderBox>
  )

  return (
    <CallButtonStack onClick={() => setPending(true)}>
      <Fab
        aria-label="save"
        color={isActiveCall ? "error" : "success"}
        sx={{ width: 50, height: 50, color: "white", fontSize: 22 }}
      >
        {isActiveCall ? <CallEndIcon /> : <PhoneOutlinedIcon />}
      </Fab>
    </CallButtonStack>
  );
};
