import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_BACKEND_STRAWBERRY_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    
    if (!token) {
        console.log("No token found in localStorage");
        return { headers };
    }

    return {
        headers: {
            ...headers,
            authorization: `Bearer ${token}`,
        },
    };
});

const link = ApolloLink.from([authLink, uploadLink]);

const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});

export default client;