import { gql } from "@apollo/client";
import { KnowledgeUploadType } from "./schemaTypes";

export const SOCIAL_AUTH = gql`
  mutation MyMutation($accessToken: String!) {
    socialAuth(accessToken: $accessToken) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;
export const LOGOUT_USER = gql`
  mutation logoutUser {
    logoutUser {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;
export const PROFILE_UPDATE = gql`
  mutation updateOrCreateAccountProfile(
    $telegram: String
    $phone: String
    $location: String
    $jobTitle: String
    $fullName: String
    $birthDate: Date
    $workFrom: Int
    $contactEmail: String
  ) {
    updateOrCreateAccountProfile(
      profileData: {
        fullName: $fullName
        birthDate: $birthDate
        jobTitle: $jobTitle
        location: $location
        telegram: $telegram
        workFrom: $workFrom
        phone: $phone
        contactEmail: $contactEmail
      }
    ) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const CREATE_JOB = gql`
  mutation createJob($jobData: JobInputCreateType) {
    createJob(jobData: $jobData) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const UPDATE_JOB = gql`
  mutation updateJob($jobData: JobInputUpdateType!) {
    updateJob(jobData: $jobData) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const DELETE_JOB = gql`
  mutation deleteJob($id: ID!) {
    deleteJob(id: $id) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const CREATE_JOB_ACTIVITY = gql`
  mutation createJobActivity($jobId: Int!) {
    createJobActivity(jobId: $jobId) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const CREATE_JOB_LINK_ACTIVITY = gql`
  mutation createJobLinkActivity(
    $jobId: ID!
    $action: String!
    $activityType: Int!
  ) {
    createJobLinkActivity(
      jobId: $jobId
      action: $action
      activityType: $activityType
    ) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;
export const DUPLICATE_JOB = gql`
  mutation duplicateJob($id: ID!) {
    duplicateJob(id: $id) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const ACTIVATE_JOB = gql`
  mutation activateJob($id: ID!) {
    activateJob(id: $id) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const CREATE_JOB_BOOKMARK = gql`
  mutation createJobBookmark(
    $text: String!
    $name: String!
    $bookmarkType: Int!
  ) {
    createJobBookmark(bookmarkType: $bookmarkType, name: $name, text: $text) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const DELETE_JOB_BOOKMARK = gql`
  mutation deleteJobBookmark($id: ID!) {
    deleteJobBookmark(id: $id) {
      ... on SuccessType {
        __typename
        message
      }
      ... on ErrorType {
        __typename
        message
      }
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($jobId: ID!, $file: Upload!) {
    uploadFile(jobId: $jobId, file: $file) {
      message
      success
      ${KnowledgeUploadType}
    }
  }
`;

export const UPLOAD_TEXT = gql`
  mutation uploadText($jobId: ID!, $text: String!) {
    uploadText(jobId: $jobId, text: $text) {
      message
      success
      knowledgeData {
        id
        source
        sourceType
        processingStatus
        createdAt
      }
    }
  }
`;

export const UPLOAD_URL = gql`
  mutation uploadUrl($jobId: ID!, $url: String!) {
    uploadUrl(jobId: $jobId, url: $url) {
      message
      success
      ${KnowledgeUploadType}
    }
  }
`;

export const DELETE_KNOWLEDGE_DATA = gql`
  mutation deleteKnowledgeData($knowledgeDataId: ID!) {
    deleteKnowledgeData(knowledgeDataId: $knowledgeDataId) {
      message
      success
      ${KnowledgeUploadType}
    }
  }
`;

export const RETRY_UPLOAD = gql`
  mutation retryUpload($knowledgeDataId: ID!) {
    retryUpload(knowledgeDataId: $knowledgeDataId) {
      message
      success
      ${KnowledgeUploadType}
    }
  }
`;

export const CHANGE_KNOWLEDGE_DATA_TEXT = gql`
  mutation changeKnowledgeDataText($knowledgeDataId: ID!, $text: String!) {
    changeKnowledgeDataText(knowledgeDataId: $knowledgeDataId, text: $text) {
      message
      success
      ${KnowledgeUploadType}
    }
  }
`;

export const CHANGE_KNOWLEDGE_DATA_STATUS = gql`
  mutation changeKnowledgeDataStatus($knowledgeDataId: ID!, $status: Boolean!) {
    changeKnowledgeDataStatus(knowledgeDataId: $knowledgeDataId, status: $status) {
      message
      success
      ${KnowledgeUploadType}
    }
  }
`;