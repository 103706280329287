import React from "react";
import {
  Avatar,
  Box,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import moment from "moment";
import ApplyJobModal from "components/modals/ApplyJobModal";
import { useActivities } from 'components/Job';
import { JobType } from '../../types/generic.d';


const HeaderStack = styled(Stack)(({ theme }) => ({
  width: "100%", 
  background: theme.palette.primary.main, 
  color: theme.palette.primary.contrastText, 
  alignItems: 'center'
}));

const ToolbarStack = styled(Stack)(({ theme }) => ({
  width: "100%", 
  flexDirection: 'row',
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2)
}));

const AvatarBox = styled(Box)(({ theme }) => ({
  position: "relative",
  width: 110,
  height: 110,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "50%",
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: 90,
    height: 90,
  },
  '& > div': {
    width: 100,
    height: 100,
    [theme.breakpoints.down('sm')]: {
      width: 80,
      height: 80,
    },
  }
}));

const DescriptionStack = styled(Stack)(({ theme }) => ({
  margin: `${theme.spacing(3)} auto`,
  gap: theme.spacing(1),
  maxWidth: '90%',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const JobHeader = ({ job }: { job: JobType }) => {
  const { createActivity } = useActivities(job)
  
  return (
    <HeaderStack>
        <ToolbarStack>
          <Box display={"flex"} justifyContent="space-between" alignItems={"center"}>
              {(!job?.activeUntil || moment(job?.activeUntil) < moment()) && (
              <>
                  <VisibilityOutlinedIcon style={{ marginRight: "5px", fontSize: 20 }} />
                  <Typography variant="h6">ONLY VISIBLE FOR YOU</Typography>
              </>
              )}
          </Box>
          <Box>
              <ApplyJobModal
                  job={job}
                  callback={() => createActivity("Click on apply button", 6)}
              />
          </Box>
        </ToolbarStack>{" "}
        {job?.company ? (
          <Typography variant={"h3"} sx={{ mb: 2 }}>
              {job?.company}
          </Typography>
        ) : null}
        <Typography variant={"h2"}>{job?.jobTitle}</Typography>{" "}
        <AvatarBox>
          <Avatar src={job?.createdBy?.profile?.avatar} />
        </AvatarBox>{" "}
        <DescriptionStack>
          <Typography>{job?.createdBy?.profile?.fullName}</Typography>
          <Typography>{job?.createdBy?.profile?.jobTitle}</Typography>
        </DescriptionStack>
    </HeaderStack>
  );
};
