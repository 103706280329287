import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import MainContainer from "components/containers/MainContainer";
import AdaptiveContainer from "components/containers/AdaptiveContainer";
import PaperContainer from "components/containers/PaperContainer";
import { PATHS } from "router/routes";
import Loader from "components/Loader";
import LinkedInButton from "components/auth/SignIn/LinkedInButton";
import useLinkedInSignin from "components/auth/SignIn/useLinkedInSignin";

const TitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(6),
}));

const PaperContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const SignIn = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  useLinkedInSignin(loading, setLoading);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate(PATHS.DASHBOARD_JOBS);
    }
  }, []);
  return (
    <MainContainer>
      <AdaptiveContainer>
        <TitleBox>
          <Typography variant="h1">
            Welcome to <b>JobLink</b> app
          </Typography>
        </TitleBox>
        <PaperContainer title="You have to be logged in to access the dashboard and create a job link">
          <PaperContent>
            {loading ? "Loading..." : <LinkedInButton />}
          </PaperContent>
          <Loader loading={loading} />
        </PaperContainer>
      </AdaptiveContainer>
    </MainContainer>
  );
};

export default SignIn;
