import React from 'react';
import { useMutation, useQuery } from "@apollo/client";
import { GET_KNOWLEDGE_DATA } from "api/queries";
import { JobType } from 'types/generic';
import { 
    CHANGE_KNOWLEDGE_DATA_STATUS, 
    CHANGE_KNOWLEDGE_DATA_TEXT, 
    DELETE_KNOWLEDGE_DATA, 
    RETRY_UPLOAD, 
    UPLOAD_FILE, 
    UPLOAD_TEXT, 
    UPLOAD_URL 
} from 'api/mutations';

const useKnowledgeBaseMutations = (job: JobType) => {
    const refetchKnowledgeData = { 
        query: GET_KNOWLEDGE_DATA, 
        variables: { jobId: job?.id }
    }

    const [uploadFile] = useMutation(UPLOAD_FILE, {
        refetchQueries: [ refetchKnowledgeData ],
    })

    const [uploadText] = useMutation(UPLOAD_TEXT, {
        refetchQueries: [ refetchKnowledgeData ],
    })

    const [uploadUrl] = useMutation(UPLOAD_URL, {
        refetchQueries: [ refetchKnowledgeData ],
    })

    const [deleteKnowledgeData] = useMutation(DELETE_KNOWLEDGE_DATA, {
        refetchQueries: [ refetchKnowledgeData ],
    })

    const [retryUpload] = useMutation(RETRY_UPLOAD, {
        refetchQueries: [ refetchKnowledgeData ],
    })

    const [changeKnowledgeDataText] = useMutation(CHANGE_KNOWLEDGE_DATA_TEXT, {
        refetchQueries: [ refetchKnowledgeData ],
    })

    const [changeKnowledgeDataStatus] = useMutation(CHANGE_KNOWLEDGE_DATA_STATUS, {
        refetchQueries: [ refetchKnowledgeData ],
    })


    return {
        uploadFile,
        uploadText,
        uploadUrl,
        deleteKnowledgeData,
        retryUpload,
        changeKnowledgeDataText,
        changeKnowledgeDataStatus
    }
}

export default useKnowledgeBaseMutations;