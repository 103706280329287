import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Snackbar,
  Stack,
  Step,
  StepLabel,
  Stepper,
  styled,
  TextareaAutosize,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FilledStarIcon from "@mui/icons-material/StarOutlined";
import { Controller, useForm } from "react-hook-form";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_JOB_BOOKMARKS, GET_JOBS } from "../../api/queries";
import ClearIcon from "@mui/icons-material/Clear";
import {
  CREATE_JOB,
  CREATE_JOB_BOOKMARK,
  DELETE_JOB_BOOKMARK,
} from "../../api/mutations";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { purple } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import BaseSnackbar from "components/Snackbar";
import { useNotifications } from "@toolpad/core";
type JobFormData = {
  greeting: string;
  managerDescription: string;
  companyInfo: string;
  projectResponsibilities: string;
  jobDescription: string;
  salary: string;
  weekendsHolidays: string;
  benefits: string;
  coreFeatures: string;
  aiCommunicationSpecifics: string;
};

type Bookmark = {
  id: number;
  name: string;
  text: string;
  bookmarkType: number;
};
type StepField = {
  label: string;
  name: keyof JobFormData;
};
type ProgressItem = {
  id: number;
  inProgress: boolean;
  percentage: number;
};

const Textarea = styled('textarea')(({ theme }) => ({
  resize: "none",
  padding: theme.spacing(3),
  fontFamily: theme.typography.fontFamily,
  width: "100%", 
  marginBottom: theme.spacing(1),
  height: 150
}));
const BookmarkItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));
type CreateJobFormProps = {
  onClose: () => void;
};
export const CreateJobForm: React.FC<CreateJobFormProps> = ({ onClose }) => {
  const notifications = useNotifications();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { data, loading } = useQuery(GET_JOB_BOOKMARKS);
  const [currentBookmarks, setCurrentBookmarks] = useState<Bookmark[]>([]);
  const [currentBookmark, setCurrentBookmark] = useState("");
  const [currentBookmarkStage, setCurrentBookmarkStage] = useState<
    number | null
  >(null);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));

  const [
    mutationCreateJobBookMark,
    { data: createJobBookmarkData, error: createJobBookmarkError },
  ] = useMutation(CREATE_JOB_BOOKMARK);
  const [mutateCreateJob, { data: jobCreateData, error: jobErrorData }] =
    useMutation(CREATE_JOB);
  const [
    mutateDeleteJob,
    { data: jobBookmarkDeleteData, error: jobBookmarkErrorData },
  ] = useMutation(DELETE_JOB_BOOKMARK);

  const [openSnackbar, toggleSnackbar] = useState(false);
  const [inProgress, toggleProgress] = useState<ProgressItem[]>([]);
  const [isInBookmark, setInBookmark] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [isDisabled, setDisabled] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const [activeStep, setActiveStep] = useState(0);

  const steps: StepField[] = [
    { label: "Greeting text of the AI manager", name: "greeting" },
    { label: "Describe your AI manager", name: "managerDescription" },
    {
      label: "Tell more about your company and job opportunity",
      name: "companyInfo",
    },
    {
      label: "Tell more about projects or job responsibilities",
      name: "projectResponsibilities",
    },
    { label: "Write the job description", name: "jobDescription" },
    {
      label: "Salary, weekends, holidays, work schedule and style",
      name: "salary",
    },
    {
      label: "Describe the benefits of working for your company",
      name: "benefits",
    },
    { label: "Indicate core features of this vacancy", name: "coreFeatures" },
    {
      label:
        "Explain the specifics of the AI manager's communication with candidates",
      name: "aiCommunicationSpecifics",
    },
  ];
  const { handleSubmit, control, setValue, watch } = useForm<JobFormData>({
    defaultValues: steps.reduce(
      (acc, step) => ({ ...acc, [step.name]: "" }),
      {},
    ),
  });

  const watchedFields = watch(`${steps[activeStep].name}`);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleBookmarkClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentBookmarkStage(index);
  };

  useEffect(() => {
    if (data && !loading) {
      const bookmarksProgress = (data?.getJobBookmarks || []).map((item: { id: any }) => ({
        id: item?.id,
        inProgress: false,
        percentage: 0,
      }));
      toggleProgress(bookmarksProgress);

      const filteredBookmarks = (data?.getJobBookmarks || []).filter(
        (item: { bookmarkType: number }) => activeStep === item.bookmarkType - 1,
      );
      setCurrentBookmarks(filteredBookmarks);
    }
  }, [data, loading, activeStep]);

  const handleSaveBookmark = () => {
    if (
      currentBookmarkStage !== null &&
      currentBookmark.trim() !== "" &&
      currentBookmark
    ) {
      const newBookmark = watch(steps[currentBookmarkStage].name);
      mutationCreateJobBookMark({
        variables: {
          text: newBookmark,
          name: currentBookmark,
          bookmarkType: currentBookmarkStage + 1,
        },
        refetchQueries: [GET_JOB_BOOKMARKS],
      });

      setAnchorEl(null);
      setCurrentBookmark("");
      setCurrentBookmarkStage(null);
    } else {
      toggleSnackbar(true);
      notifications.show("Error!  Enter valid name", 
      {
        severity: 'error',
        autoHideDuration: 3000,
    });
    }
  };
  const handleDelete = (id: number) => {
    mutateDeleteJob({
      variables: {
        id: id,
      },
      refetchQueries: [GET_JOB_BOOKMARKS],
    });
  };
  const handleBookmarkSelect = (bookmark: Bookmark) => {
    setValue(steps[activeStep].name, bookmark.text);
  };
  const onSubmit = (data: { [key: number]: string }) => {
    onClose();
    mutateCreateJob({
      variables: { jobData: data },
      refetchQueries: [GET_JOBS],
    });
  };

  const handleSetTime = async (bookmark: Bookmark) => {
    toggleProgress([
      ...inProgress.filter((item) => item.id !== bookmark.id),
      {
        id: bookmark.id,
        inProgress: true,
        percentage: 0,
      },
    ]);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      toggleProgress((prev: ProgressItem[]) =>
        prev.map((item) => {
          if (item.inProgress && item.percentage < 100) {
            return { ...item, percentage: item.percentage + 4 };
          } else if (item.percentage >= 100) {
            handleDelete(item.id);
            return { ...item, inProgress: false };
          }
          return item;
        }),
      );
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (currentBookmarks.length > 0) {
      const hasBookmarkText = currentBookmarks.some((bookmark) => watchedFields === bookmark.text);
      setInBookmark((prev) => prev.map((_, index) => (index === activeStep ? hasBookmarkText : _)));
    }
  }, [currentBookmarks, watchedFields, activeStep]);
  
  useEffect(() => {
    if (watchedFields) {
      setDisabled((prev) =>
        prev.map((value, index) => (index === activeStep ? false : value)),
      );
    } else {
      setDisabled((prev) =>
        prev.map((value, index) => (index === activeStep ? true : value)),
      );
    }
  }, [watchedFields, activeStep]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Box p={isMobile ? 0 : 1} display="flex" gap={4}>
          <Box flex={3}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step: { label: string; name: string }, index) => (
                <Step key={step.name}>
                  <StepLabel>
                    {" "}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <p style={{ marginRight: "10px" }}>{step.label}</p>
                      <IconButton
                        onClick={(event) => handleBookmarkClick(event, index)}
                        disabled={activeStep < index || isDisabled[index]}
                      >
                        {isInBookmark[index] ? (
                          <FilledStarIcon />
                        ) : (
                          <StarBorderIcon />
                        )}
                      </IconButton>
                    </Box>
                  </StepLabel>
                  {activeStep === index && (
                    <Stack>
                      <Controller
                        //@ts-ignore
                        name={step.name}
                        control={control}
                        render={({ field }) => (
                          <Textarea
                            {...field}
                            value={field.value || ""}
                            placeholder={`Enter text for "${step.label}"`}
                            autoFocus
                          />
                        )}
                      />
                      <Box>
                        <Accordion
                          sx={{
                            mt: 2,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Typography>Bookmarks</Typography>
                          </AccordionSummary>
                          {currentBookmarks.length > 0 && (
                            <AccordionDetails>
                              <List component="nav" aria-label="bookmarks">
                                {(currentBookmarks || []).map(
                                  (bookmark, idx) => (
                                    <BookmarkItem key={idx}>
                                      <ListItemText
                                        style={{ width: "70%" }}
                                        primary={bookmark.name}
                                        onClick={() =>
                                          handleBookmarkSelect(bookmark)
                                        }
                                      />
                                      {inProgress?.some(
                                        (item) =>
                                          item.id === bookmark.id &&
                                          !item.inProgress,
                                      ) ? (
                                        <Button
                                          onClick={() =>
                                            handleSetTime(bookmark)
                                          }
                                          style={{
                                            backgroundColor: "black",
                                            color: "white",
                                            marginRight: "5px",
                                            visibility: inProgress?.some(
                                              (item) =>
                                                item.id === bookmark.id &&
                                                !item.inProgress,
                                            )
                                              ? "visible"
                                              : "hidden",
                                          }}
                                        >
                                          <DeleteForeverIcon />
                                        </Button>
                                      ) : (
                                        <Box
                                          position="relative"
                                          display="inline-flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          style={{
                                            marginRight: "8px",
                                          }}
                                        >
                                          <CircularProgress
                                            variant="determinate"
                                            size={36}
                                            style={{
                                              color: purple[500],
                                            }}
                                            value={
                                              inProgress.find(
                                                (item) =>
                                                  item.id === bookmark.id,
                                              )?.percentage || 0
                                            }
                                          />
                                          <IconButton
                                            onClick={() => {
                                              toggleProgress([
                                                ...inProgress.filter(
                                                  (item) =>
                                                    item.id !== bookmark.id,
                                                ),
                                                {
                                                  id: bookmark.id,
                                                  inProgress: false,
                                                  percentage: 0,
                                                },
                                              ]);
                                            }}
                                            style={{
                                              position: "absolute",
                                              padding: 0,
                                              color: purple[500],
                                              fontSize: 24,
                                            }}
                                          >
                                            <ClearIcon />
                                          </IconButton>
                                        </Box>
                                      )}
                                    </BookmarkItem>
                                  ),
                                )}
                              </List>
                            </AccordionDetails>
                          )}
                        </Accordion>
                      </Box>
                      <Stack direction={"row"} mt={3} gap={2}>
                        <Button
                          onClick={handleBack}
                          disabled={activeStep === 0}
                          variant="contained"
                          color="secondary"
                        >
                          Back
                        </Button>
                        {activeStep === steps.length - 1 ? (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Finish
                          </Button>
                        ) : (
                          <Button
                            onClick={handleNext}
                            disabled={activeStep === steps.length - 1}
                            variant="contained"
                            color="primary"
                          >
                            Next
                          </Button>
                        )}{" "}
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Skip
                        </Button>
                      </Stack>
                    </Stack>
                  )}
                </Step>
              ))}
            </Stepper>
          </Box>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box p={2} borderRadius={2} minWidth={200} boxShadow={3}>
              <Typography variant="h6" gutterBottom>
                Add Bookmark
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Bookmark name"
                value={currentBookmark}
                onChange={(e) => setCurrentBookmark(e.target.value)}
                size="small"
                margin="dense"
              />
              <Box mt={1} display="flex" justifyContent="flex-end">
                <Button
                  onClick={handleSaveBookmark}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Popover>
        </Box>{" "}
      </Stack>
    </form>
  );
};
