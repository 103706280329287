import React, { useState, useEffect } from 'react';

export const useCallAction = (callback: () => void) => {
    const [isPending, setPending] = useState(false);
    const [callActionProgress, setProgress] = useState(0);
    
    useEffect(() => {
        if (isPending) {
            const interval = setInterval(() => {
            if (isPending) {
                setProgress((prevState) => prevState + 7);
            }
            if (callActionProgress > 100) {
                setPending(false);
                setProgress(0);
                callback()
            }
            }, 100);
            return () => clearInterval(interval);
        }
    }, [isPending, callActionProgress]);

    const cancelAction = () => {
        setPending(false);
        setProgress(0)
    }

    return {
        isPending,
        setPending,
        callActionProgress,
        cancelAction
    }
}