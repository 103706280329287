import React from "react";
import { Box, styled, Stack } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { PATHS } from '../../router/routes.js';
import Footer from "./Footer";
import BaseHeader from './BaseHeader';
import PublicHeader from './PublicHeader';
import DashboardHeader from './DashboardHeader';

const RootStack = styled(Stack)(({ theme }) => ({
  height: '100vh',  
}));

const ContentBox = styled(Box)(({ theme }) => ({
  flex: 1,
  paddingTop: theme.spacing(8),
}));

  const AppLayout = ({ children }: {children: any }) => {
    const { pathname } = useLocation();

    const LAYOUT_PAGES = {
      auth: [PATHS.SIGN_IN],
      base: [PATHS.PAGE_NOT_FOUND],
      job: /^\/[^/]+$/.test(pathname) && pathname,
      dashboard: [PATHS.DASHBOARD]
    }

    if (matchPathname(pathname, LAYOUT_PAGES.base)) return (
      <RootStack>
        <BaseHeader />
        <ContentBox>
            {children}
        </ContentBox>
      </RootStack>
    )

    if (matchPathname(pathname, LAYOUT_PAGES.auth)) return (
      <RootStack>
        <BaseHeader />
          {children}
        <Footer />
      </RootStack>
    );

    if (LAYOUT_PAGES.job) return (
      <RootStack>
        <PublicHeader />
        <ContentBox>
          {children}
        </ContentBox>
      </RootStack>
    )

    if (matchPathname(pathname, LAYOUT_PAGES.dashboard)) return (
      <RootStack>
        <DashboardHeader />
        <ContentBox>
            {children}
        </ContentBox>
      </RootStack>
    );
  
    return (
        <RootStack>
          <BaseHeader />
          <ContentBox>
            {children}
          </ContentBox>
        </RootStack>
    )
  
  };

  const matchPathname = (pathname: string | any[], matchArray: any[]) => {
    return !!matchArray?.find((el) => pathname.includes(el))
  }
  
  export default AppLayout;
  