import React, { useEffect, useState } from 'react';
import { Typography } from "@mui/material";

const DelayedLoaderText = () => {
    const [displayLoading, setLoading] = useState(false);

    useEffect(() => {
        setTimeout(()=>{
            setLoading(true)
        }, 1000)
    },[])

    if (!displayLoading) return null;

    return <Typography>Loading...</Typography>;
}

export default DelayedLoaderText;