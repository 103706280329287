import React, { useState } from "react";
import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  Tabs,
  Tab,
} from "@mui/material";
import { JobType } from "types/generic";
import ModalPaper from "components/modals/ModalPaper";
import Chat from "components/Dashboard/Chat";
import useUpdateJob from "components/hooks/useUpdateJob";
import TrainingGuide from "components/Dashboard/TrainingGuide";
import { VOICE_MAPPER } from "constants/common";

type ModalProps = {
  job: JobType;
  trigger: React.ReactElement;
};
const ModalBody = styled(Box)(({ theme }) => ({
  // height: 630,
  // overflow: 'auto',
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: theme.spacing(3),
  "& > *": {
    flex: 1,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  width: '50%',
}))

const TrainingGuideModal = ({ job, trigger }: ModalProps) => {

  const [isOpen, setOpen] = useState(false);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const [tabValue, setTabValue] = useState(1);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  return (
    <ModalPaper
      title={`Edit training guide`}
      trigger={trigger}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <ModalBody>
        {isMobile ? (
          <Box>
            <Tabs
              sx={{ width: "100%" }}
              value={tabValue}
              onChange={handleChange}
            >
              <StyledTab
                value={1}
                label={
                  <Typography variant="h6">
                    Current Training Guide
                  </Typography>
                }
              />
              <StyledTab
                value={2}
                label={
                  <Typography variant="h6">
                    {`Chat with ${VOICE_MAPPER?.[job.voiceType]}`}
                  </Typography>
                }
              />
            </Tabs>

            <TabPanel value={tabValue} index={1}>
              {" "}
              <TrainingGuide job={job}/>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <Chat job={job} />
            </TabPanel>
          </Box>
        ) : (
          <>
            <TrainingGuide job={job}/>
            <Chat job={job}  height={582} withTitle={true} />{" "}
          </>
        )}
      </ModalBody>
    </ModalPaper>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: {};
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, style, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={style}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            mt: 3,
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default TrainingGuideModal;
