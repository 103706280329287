import React, { useState } from "react";
import {
  Box,
  styled,
} from "@mui/material";
import { JobType } from "types/generic";
import ModalPaper from "components/modals/ModalPaper";
import KnowledgeBase from "components/KnowledgeBase";

type GreetingsProps = {
  job: JobType;
  trigger: React.ReactElement;
};

const ModalBody = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const KnowledgeBaseModal = ({ job, trigger }: GreetingsProps) => {
  const [isOpen, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ModalPaper
      title={`Fill AI with more facts about the job or business`}
      trigger={trigger}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <ModalBody>
        <KnowledgeBase job={job} />
      </ModalBody>
    </ModalPaper>
  );
};

export default KnowledgeBaseModal;
